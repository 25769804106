<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin phiếu thu cũ'">
          <template v-slot:preview>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="col-md-12 mb-5">
                <div class="row pl-0">
                  <div class="col-md-6">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-3 pl-0"
                        >Chương trình:</label
                      >
                      <b-col md="6">
                        <vue-autosuggest
                          v-model="searchTradeInProgram"
                          :suggestions="filteredOptionsTradeInProgram"
                          @selected="onSelectedTradeInProgram"
                          :limit="10"
                          @input="onInputChangeTradeInProgram"
                          :input-props="{
                            id: 'autosuggest__input',
                            placeholder: 'Nhập tên chượng trình',
                            style: 'border-radius:0px!important',
                            disabled: true,
                          }"
                          :should-render-suggestions="
                            (size, loading) => size >= 0 && !loading
                          "
                        >
                        </vue-autosuggest>
                      </b-col>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-4 pl-0"
                        >Ngày tạo:</label
                      >
                      <span class="ml-4">{{ createdAt }}</span>
                    </div>
                  </div>
                </div>
                <div class="row pl-0 mt-4">
                  <div class="col-md-6">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-3 pl-0"
                        >Sản phẩm:</label
                      >
                      <b-col md="6">
                        <vue-autosuggest
                          v-model="searchProduct"
                          :suggestions="filteredOptionsProduct"
                          @selected="onSelectedProduct"
                          :limit="10"
                          @input="onInputChangeProduct"
                          :input-props="{
                            id: 'autosuggest__input',
                            placeholder: 'Nhập tên sản phẩm',
                            style: 'border-radius:0px!important',
                            disabled: true,
                          }"
                          :should-render-suggestions="
                            (size, loading) => size >= 0 && !loading
                          "
                        >
                          <template slot-scope="{ suggestion }">
                            <div>{{ suggestion.item.productName }}</div>
                          </template>
                        </vue-autosuggest>
                      </b-col>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-4 pl-0"
                        >Người tạo:</label
                      >
                      <span class="ml-4">{{ createdBy }}</span>
                    </div>
                  </div>
                </div>
                <div class="row pl-0 mt-4">
                  <div class="col-md-6">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-3 pl-0"
                        >Khách hàng:</label
                      >
                      <b-col md="6">
                        <vue-autosuggest
                          v-model="searchCustomer"
                          :suggestions="filteredOptionsCustomer"
                          @selected="onSelectedCustomer"
                          :limit="10"
                          @input="onInputChangeCustomer"
                          :input-props="{
                            id: 'autosuggest__input',
                            placeholder: 'Nhập tên, SĐT khách hàng',
                            style: 'border-radius:0px!important',
                            disabled: true,
                          }"
                          :should-render-suggestions="
                            (size, loading) => size >= 0 && !loading
                          "
                        >
                        </vue-autosuggest>
                      </b-col>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-4 pl-0"
                        >Cửa hàng:</label
                      >
                      <b-col md="6">
                        <vue-autosuggest
                          v-model="searchStore"
                          :suggestions="filteredOptionsStore"
                          @selected="onSelectedStore"
                          :limit="10"
                          @input="onInputChangeStore"
                          :input-props="{
                            id: 'autosuggest__input',
                            placeholder: 'Nhập tên cửa hàng',
                            style: 'border-radius:0px!important',
                            disabled: true,
                          }"
                          :should-render-suggestions="
                            (size, loading) => size >= 0 && !loading
                          "
                        >
                        </vue-autosuggest>
                      </b-col>
                    </div>
                  </div>
                </div>
                <div class="row pl-0 mt-4">
                  <div class="col-md-6">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-3 pl-0"
                        >IMEI:</label
                      >
                      <b-col md="6">
                        <vue-autosuggest
                          v-model="searchImei"
                          :suggestions="filteredOptionsImei"
                          @selected="onSelectedImei"
                          :limit="10"
                          @input="onInputChangeImei"
                          :input-props="{
                            id: 'autosuggest__input',
                            placeholder: 'Nhập mã IMEI',
                            style: 'border-radius:0px!important',
                            disabled: true,
                          }"
                          :should-render-suggestions="
                            (size, loading) => size >= 0 && !loading
                          "
                        >
                        </vue-autosuggest>
                      </b-col>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-4 pl-0"
                        >Trạng thái:</label
                      >
                      <b-col md="6">
                        <b-form-select
                          size="sm"
                          id="input-3"
                          v-model="selectedStatus"
                          :options="listStatus"
                          required
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                        ></b-form-select>
                      </b-col>
                    </div>
                  </div>
                </div>
                <div class="row pl-0 mt-4">
                  <div class="col-md-6"></div>
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-4 pl-0"
                        >Loại:</label
                      >
                      <b-col md="6">
                        <b-form-select
                          size="sm"
                          id="input-3"
                          v-model="selectedType"
                          :options="listType"
                          required
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                        ></b-form-select>
                      </b-col>
                    </div>
                  </div>
                </div>
                <div class="row pl-0 mt-4">
                  <div class="col-md-6">
                    <div
                      class="row"
                      v-for="(item, index) in listGroupOption"
                      :key="index"
                    >
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-3 pl-0"
                        >{{ item.criterialName }}</label
                      >
                      <b-table
                        class="table-bordered table-hover col-md-12"
                        :fields="fields"
                        :items="item.listItem"
                      >
                        <template v-slot:cell(amount)="row">
                          <div class="row d-flex justify-content-end">
                            <div class="d-flex justify-content-end pr-2">
                              <span v-if="row.item.operatorType === 1"
                                >(+)
                              </span>
                              <span v-if="row.item.operatorType === 2"
                                >(-)
                              </span>
                              <span
                                v-text="formatMoney(row.item.amount)"
                              ></span>
                            </div>
                          </div>
                        </template>
                        <template v-slot:cell(selected)="row">
                          <div style="text-align: center">
                            <b-form-checkbox
                              v-if="row.item.optionType === 2"
                              id="checkbox-1"
                              v-model="row.item.selected"
                              name="checkbox-1"
                              :disabled="true"
                            >
                            </b-form-checkbox>
                            <b-form-radio
                              v-else-if="row.item.optionType === 1"
                              v-model="row.item.selected"
                              :disabled="true"
                              :name="row.item.name"
                              :value="row.item.id"
                            ></b-form-radio>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-4">
                    <div class="row">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-12 pl-0"
                        >Tổng quan:</label
                      >
                    </div>
                    <div class="row mt-4">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-6 pl-0"
                        >Giá thu trên sản phẩm:</label
                      >
                      <span>{{ formatMoney(productBuyingPrice) }}</span>
                    </div>
                    <div class="row mt-4">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-6 pl-0"
                        >Phát sinh sau đánh giá:</label
                      >
                      <span>{{ formatMoney(totalCriteriaPrice) }}</span>
                    </div>
                    <div class="row mt-4">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-6 pl-0"
                        >Giá thu dự kiến:</label
                      >
                      <span>{{ formatMoney(estimationBuydingPrice) }}</span>
                    </div>
                    <div class="row mt-4">
                      <label
                        style="font-weight: 600"
                        for="input-status"
                        class="col-md-6 pl-0"
                        >Giá thu cuối cùng:</label
                      >
                      <span>{{ formatMoney(finalBuyingPrice) }}</span>
                    </div>
                    <div class="row mt-4">
                      <label style="font-weight: 600" for="input-description"
                        >Ghi chú:</label
                      >
                    </div>
                    <div class="row">
                      <b-form-textarea
                        id="textarea-description"
                        v-model="note"
                        placeholder="Ghi chú"
                        rows="5"
                      ></b-form-textarea>
                    </div>
                    <div class="row" style="padding-left: 0px">
                      <div class="mb-5 mt-5">
                        <button
                          type="button"
                          class="
                            btn btn-primary
                            font-weight-bolder
                            btn-sm
                            button
                            mr-4
                          "
                          @click="onSubmit"
                        >
                          Lưu phiếu
                        </button>
                        <button
                          type="button"
                          class="
                            btn btn-primary
                            font-weight-bolder
                            btn-sm
                            button
                          "
                          @click="importTradeIn"
                        >
                          Tạo phiếu nhập máy cũ
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-form>
          </template>
          <template v-slot:foot>
            <!-- <b-col lg="4" class="pb-2"> -->
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="createUser"
              v-show="false"
              >Lưu</b-button
            >
            <!-- </b-col> -->
            <!-- <b-col lg="2" class="pb-2"> -->
            <router-link to="/trade-in" tag="button">
              <b-button
                style="font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
            <!-- </b-col> -->
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
input::-webkit-calendar-picker-indicator {
  display: none;
}

.label-input {
  font-weight: 500;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import ApiService from '@/core/services/api.service';
import decounce from 'debounce';
import { TIME_TRIGGER } from './../../../utils/constants';

export default {
  mixins: [validationMixin],
  data() {
    return {
      valid: true,
      form: {
        username: '',
        password: '',
      },
      selectedEmployee: null,
      selectedStatus: 1,
      listStatus: [
        { id: 1, name: 'Đã báo giá' },
        { id: 2, name: 'Đã thu cũ' },
      ],
      selectedRole: null,
      listRole: [],
      selectedId: null,
      isNew: true,
      searchEmployee: '',
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      listTradeInProgram: [
        {
          id: 1,
          name: 'AAA',
        },
        {
          id: 2,
          name: 'BBBB',
        },
      ],
      selectedTradeInProgram: 1,
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      filteredOptionsTradeInProgram: [],
      optionsTradeInProgram: [
        {
          data: [],
        },
      ],
      searchCustomer: '',
      filteredOptionsCustomer: [],
      optionsCustomer: [
        {
          data: [],
        },
      ],
      searchTradeInProgram: '',
      filteredOptionsStore: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      searchStore: '',
      filteredOptionsImei: [],
      optionsImei: [
        {
          data: [],
        },
      ],
      searchImei: '',
      tradeInId: null,
      createdBy: '',
      createdAt: '',
      listGroupOption: [],
      listCriterialOption: [],
      fields: [
        {
          key: 'name',
          label: 'Tiêu chí',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '50%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'selected',
          label: 'Lựa chọn',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '30%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'amount',
          label: 'Giá trị',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-left',
        },
      ],
      productBuyingPrice: 0,
      finalBuyingPrice: 0,
      estimationBuydingPrice: 0,
      totalCriteriaPrice: 0,
      note: '',
      selectedType: null,
      listType: [
        {
          id: 1,
          name: 'Thu cũ',
        },
        {
          id: 2,
          name: 'Đổi mới',
        },
      ],
    };
  },
  validations: {
    form: {},
  },
  components: {
    KTCodePreview,
  },
  created() {
    // this.fetchData();
    // this.fetchDataEmployee();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Thu cũ- đổi mới', route: '/trade-in' },
      { title: 'Danh sách phiếu thu cũ', route: '/trade-in' },
      { title: 'Thông tin phiếu thu cũ' },
    ]);
    this.tradeInId = this.$route.query.id;
    this.getTradeInById();
  },
  methods: {
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    createUser: async function () {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const username = this.$v.form.username.$model;
      const password = this.$v.form.password.$model;
      const employeeId = this.selectedEmployee;
      const roleId = this.selectedRole;
      let isActive = false;
      if (this.selectedStatus === 1) {
        isActive = true;
      }
      let data = {
        username: username,
        password: password,
        employeeId: employeeId,
        roleId: roleId,
        isActive: isActive,
      };

      ApiService.setHeader();
      ApiService.post('user/create', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess('Thêm mới tài khoản thành công!');
            setTimeout(() => {
              this.$router.push({
                path: '/users',
              });
            }, 1000);
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    fetchData: async function () {
      this.listRole = [];
      let param = {
        page: 1,
        limit: 10,
      };
      let paramQuery = {
        params: param,
      };
      ApiService.query('role', paramQuery).then(({ data }) => {
        data.data.list_role.forEach((element) => {
          let item = {
            id: element.id,
            name: element.name,
          };
          this.listRole.push(item);
        });
      });
    },
    fetchDataEmployee: async function () {
      //  init params
      let param = {
        page: 1,
        pageSize: 1000,
        fullName: '',
      };
      let paramQuery = {
        params: {
          param,
        },
      };
      ApiService.setHeader();
      ApiService.query('employees', paramQuery)
        .then((response) => {
          this.totalPages = response.data.totalPages;
          this.totalItems = response.data.totalItems;
          response.data.employees.forEach((item) => {
            let employee = {
              id: item.id,
              name: item.fullName,
            };
            this.options[0].data.push(employee);
          });
        })
        .catch(() => {});
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onSelectedEmployee(option) {
      this.searchEmployee = option.item.name;
      this.selectedEmployee = option.item.id;
    },
    onInputChange(text) {
      if (text === '') {
        this.inputSearchProductProp.disabled = true;
      }
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
      //this.onChangeStore();
    },
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;

      this.debounceInput();
    },
    onSelectedTradeInProgram(option) {
      this.searchTradeInProgram = option.item.name;
    },
    onInputChangeTradeInProgram(text) {
      if (!text) {
        text = '';
      }
      this.searchTradeInProgram = text;

      //   this.debounceInput();
    },
    onSelectedCustomer(option) {
      this.searchCustomer = option.item.name;
    },
    onInputChangeCustomer(text) {
      if (!text) {
        text = '';
      }
      this.searchCustomer = text;

      //   this.debounceInput();
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStore = text;

      //   this.debounceInput();
    },
    onSelectedImei(option) {
      this.searchImei = option.item.name;
    },
    onInputChangeImei(text) {
      if (!text) {
        text = '';
      }
      this.searchImei = text;

      //   this.debounceInput();
    },
    fetchProduct(textSearch) {
      this.listProduct = [];
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch?searchProduct=${textSearch}&storeId=&includeParent=false`
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        if (textSearch !== this.searchProduct) {
          let nextTextSearch = this.searchProduct;
          this.fetchProduct(nextTextSearch);
        }
      });
    },
    debounceInput: decounce(function () {
      if (!this.isSearching) {
        let textSearch = this.searchProduct;
        this.fetchProduct(textSearch);
      }
    }, TIME_TRIGGER),
    getTradeInById: function () {
      ApiService.setHeader();
      ApiService.get(`tradeIn/${this.tradeInId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.searchTradeInProgram = data.data.tradeInProgramName;
            this.searchProduct = data.data.productName;
            this.searchCustomer = data.data.customerName;
            this.searchStore = data.data.storeName;
            this.searchImei = data.data.imeiCode;
            this.selectedStatus = data.data.status;
            this.createdBy = data.data.createdBy;
            this.createdAt = data.data.createdDate;
            this.listGroupOption = data.data.listCreteriaGroup;
            this.productBuyingPrice = data.data.productBuyingPrice;
            this.totalCriteriaPrice = data.data.totalCriteriaPrice;
            this.estimationBuydingPrice = data.data.estimationBuydingPrice;
            this.finalBuyingPrice = data.data.finalBuyingPrice;
            this.note = data.data.note;
            this.selectedType = data.data.tradeInType;
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    checkRadioSelected(item) {
      let checked = 0;
      if (item.selected) {
        checked = item.id;
      }
      return checked;
    },
    importTradeIn: function () {
      const { href } = this.$router.resolve({
        name: 'import-trade-in',
        query: { id: this.tradeInId },
      });
      window.open(href, '_blank');
    },
    onSubmit: async function () {
      const data = {
        id: this.tradeInId,
        typeTradeIn: this.selectedType,
        status: this.selectedStatus,
        note: this.note,
      };

      ApiService.setHeader();
      ApiService.put('tradeIn', data)
        .then((response) => {
          const status = response.data.status;
          const message = response.data.message;
          if (status === 1) {
            this.makeToastSuccess(message);
            history.back();
          } else {
            this.makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFaile(message);
        });
    },
  },
};
</script>
